
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import { getTranslatedEnumOptions } from "@/application/util/enum";

export default Vue.extend({
  name: "KEnumSelect",
  props: {
    enum: {
      type: Object,
      default: () => ({}),
    },
    translationPrefix: {
      type: String,
    },
  },
  components: { KSelect },
  computed: {
    items() {
      return getTranslatedEnumOptions(this.enum, this.translationPrefix);
    },
  },
});
